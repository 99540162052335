<template>
    <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="row d-print-none">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">QR Print</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row">
<!--                    <div class="col-sm-6">-->
                        <div class="float-left mt-5">
                            <img :src="qrUrl" alt="" v-if="qrUrl">
                        </div>

<!--                    </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--                    <div class="col-sm-4 offset-sm-2">-->
<!--                    </div>&lt;!&ndash; end col &ndash;&gt;-->
                </div>
                <!-- end row -->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="clearfix pt-3 table-responsive">
                            <table class="table table-bordered mb-0">
                                <thead>
                                <tr>
                                    <th>Step</th>
                                    <th>Attester</th>
                                    <th>Physical Signature</th>
                                    <th>Completed</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(step, index) in definition_steps">
                                    <tr v-for="(attester, index2) in step.attestors">
                                        <td :rowspan="step.attestors.length" v-if="index2===0">{{step.name}}</td>
                                        <td>{{attester.short_name}}</td>
                                        <td></td>
                                        <td :rowspan="step.attestors.length" v-if="index2===0">
                                            <div class="checkbox text-muted"></div>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="d-print-none mt-4">
                    <div class="text-right">
                        <a class="btn btn-primary rounded" href="javascript:window.print()"><i class="mdi mdi-printer"></i>
                            Print</a>
                        <a @click="$router.go(-1)" class="btn rounded btn-danger ml-1" href="javascript: void(0);"><i
                                class="mdi mdi-close-box"></i>&nbsp;Close</a>
                    </div>
                </div>
                <!-- end buttons -->
            </div> <!-- end col-->
        </div>
    </div>
</template>

<script>
    import QRCode from "qrcode";

    export default {
        name: "ProcessQR",
        props: ['registryid', 'processdefinitionid', 'processid'],
        data() {
            return {
                definition_steps: null,
                qrUrl: null,
            }
        },
        mounted() {
            this.getProcessDefinition();
            this.generateQr();
        },
        methods: {
            async getProcessDefinition() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps`, {
                        params: {
                            page: 0,
                            per_page: 100
                        }
                    });
                    this.definition_steps = data.process_definition_steps;
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            async generateQr() {
                let qr = `${this.$BLOCK_EXPLORER}/provenance/${this.processid}`;

                this.qrUrl = await QRCode.toDataURL(qr, {
                    width: 200,
                    type: 'svg',
                    color: {
                        dark: '#000',  // Blue dots
                        light: '#0000' // Transparent background
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #adb5bd;
        display: inline-block;
    }
</style>